import { ErrorResopnse } from 'api/types';
import axiosServices from 'utils/axios';

export interface TestCount {
  success: number;
  skip: number;
  error: number;
  fail: number;
  retried: number;
}

export interface BuildDetails {
  buildId: string;
  finishedProcessing: boolean;
  organization: string;
  repository: string;
  code: string;
  date: string;
  ciRunID: string;
  ciWorkflow?: string;
  ciJob?: string;
  ciLink: string;
  ci: string;
  testConfiguration: {
    operatingSystem: string;
    languageVersion: string;
    testFrameworkVersion?: string;
  };
  countPerOutcome: TestCount;
}

export interface BuildTestRun {
  testId: string;
  testRunId: string;
  containerName: string;
  containerId: string;
  testName: string;
  executionTimeInMs: number;
  outcome: string;
}

export interface BuildPaginatedResult {
  details: BuildDetails;
  pagination: {
    currentPage: number;
    totalItemsInPage: number;
    totalPages: number;
  };
  result: BuildTestRun[];
}

export interface BuildsPaginatedResult {
  pagination: {
    currentPage: number;
    totalItemsInPage: number;
    totalPages: number;
  };
  result: BuildResponse[];
}

export interface BuildResponse {
  buildCode: string;
  branch?: string;
  isPullRequest: boolean;
  finishedProcessing: boolean;
  codeRepository: string;
  date: string;
  ciRunID: number;
  ciWorkflow?: string;
  ciJob?: string;
  ci: string;
  hasBrokenTests: boolean;
  testConfiguration: {
    operatingSystem?: string;
    languageVersion?: string;
    testFrameworkVersion?: string;
  };
  totalExecutionTime: number;
}

const buildService = {
  getBuild: async (org: string, repo: string, buildHash: string, keyword?: string, outcome?: string, pageNumber: number = 1) => {
    try {
      const response = await axiosServices.get<BuildPaginatedResult>(
        `/gh/orgs/${org}/repositories/${repo}/builds/${buildHash}?page=${pageNumber}`,
        {
          params: {
            keyword: keyword,
            outcome: outcome
          }
        }
      );
      return response.data;
    } catch (e: any) {
      const message = (e as ErrorResopnse).error;
      throw new Error(message);
    }
  },
  getBuilds: async (org: string, repo: string, page: number = 1) => {
    try {
      const response = await axiosServices.get<BuildsPaginatedResult>(`/gh/orgs/${org}/repositories/${repo}/builds`, {
        params: {
          page
        }
      });
      return response.data;
    } catch (e: any) {
      const message = (e as ErrorResopnse).error;
      throw new Error(message);
    }
  },
  getBuildsInPR: async (org: string, repo: string, pullRequestNumber: string, page: number = 1) => {
    try {
      const response = await axiosServices.get<BuildsPaginatedResult>(
        `/gh/orgs/${org}/repositories/${repo}/builds/pr/${pullRequestNumber}`,
        {
          params: {
            page
          }
        }
      );
      return response.data;
    } catch (e: any) {
      const message = (e as ErrorResopnse).error;
      throw new Error(message);
    }
  }
};

export default buildService;
