import brokenTestService, { CurrentBrokenTests } from 'api/services/brokenTestService';
import celebrate from 'assets/images/award_15815088.png';
import { OrganizationContext } from 'contexts/OrganisationsContext';
import { useContext, useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import BrokenTestsList from './broken-tests-list';
import { StyledCeleberateImg, StyledContentWrapper } from 'components/StyledCelebrationImages';
import { useAlert } from 'contexts/AlretContext';
import CenteredContainer from 'components/CenteredLoader';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';

const BrokenTests = () => {
  const { orgName, repoName } = useParams();
  const [brokenTests, setBrokenTests] = useState<CurrentBrokenTests>();

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const alertProvider = useAlert();

  const fetchBrokenTests = async () => {
    setHasError(false);

    if (orgName && repoName) {
      setIsLoading(true);

      const data = await brokenTestService.getCurrent(orgName, repoName).catch((e: Error) => {
        alertProvider.showAlert(`Problem retrieving broken tests data. \nError: ${e.message}`);
        setHasError(true);
      });

      setIsLoading(false);

      if (data) {
        setBrokenTests(data);
      }
    }
  };

  const randomSentence = () => {
    let randomSentences: string[] = [
      "Life is good, isn't it?",
      "It's time for a coffee!",
      'Go break some tests now!',
      'Only if it could always be like this!',
      'Keep up the good work!'
    ];

    const randomIndex = Math.floor(Math.random() * randomSentences.length);
    return randomSentences[randomIndex];
  };

  useEffect(() => {
    fetchBrokenTests();
  }, [orgName, repoName]);

  if (brokenTests?.brokenTests.length === 0) {
    return (
      <CenteredContainer>
        <Box flexDirection={'column'} textAlign={'center'}>
          <Typography fontSize={42} fontWeight={600} mb={4}>
            You have no broken tests!
          </Typography>
          <StyledCeleberateImg src={celebrate}></StyledCeleberateImg>
          <Typography fontSize={28} mt={2}>{randomSentence()}</Typography>
        </Box>
      </CenteredContainer>
    );
  }

  return (
    <>
      <Helmet>
        <title>Broken Tests</title>
      </Helmet>
      <Typography variant="h2" mb={2}>
        Broken Tests
      </Typography>
      {brokenTests ? <BrokenTestsList brokenTests={brokenTests} /> : null}

      {isLoading ? (
        <CenteredContainer>
          <CircularProgress></CircularProgress>
        </CenteredContainer>
      ) : null}

      {hasError ? (
        <CenteredContainer>
          <Typography ml={1} fontWeight={500} fontSize={'1.5rem'}>
            Data is not available
          </Typography>
        </CenteredContainer>
      ) : null}
    </>
  );
};

export default BrokenTests;
