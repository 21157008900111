import { BuildDetails } from 'api/services/buildService';
import MainCard from 'components/MainCard';
import { Typography, Stack, Box, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import unitConverter from 'utils/unitConverter';

interface Props {
  details: BuildDetails;
}

const DetailsBlock = styled(Box)({});

const DetailsBlockHeader = styled(Typography)({
  fontWeight: 600,
  whiteSpace: 'nowrap'
});

const LinkStyled = styled(Link)({
  color: '#4680ff',
  textDecoration: 'none'
});

const BuildDetailsHeader = ({ details }: Props) => {
  return (
    <MainCard>
      <Typography variant="body1" sx={{ fontSize: 20, fontWeight: 500 }}>
        Build {details.ciRunID}
      </Typography>
      <Stack mt={3} flexDirection={'row'}>
        <Grid container rowSpacing={{ xs: 2, xl: 0 }}>
          <Grid item xs={4} xl={2.4}>
            <DetailsBlock>
              <DetailsBlockHeader variant="body1">Date</DetailsBlockHeader>
              <Typography mt={1} variant="body1">
                {unitConverter.convertDate(details.date)}
              </Typography>
            </DetailsBlock>
          </Grid>
          <Grid item xs={4} xl={2.4}>
            <DetailsBlock>
              <DetailsBlockHeader variant="body1">Workflow Name</DetailsBlockHeader>
              <Typography mt={1} variant="body1">
                {details.ciWorkflow + ' - ' + details.ciJob}
              </Typography>
            </DetailsBlock>
          </Grid>
          <Grid item xs={4} xl={2.4}>
            <DetailsBlock>
              <DetailsBlockHeader variant="body1">Test Configuration</DetailsBlockHeader>
              <Stack>
                <DetailsBlock>
                  {details.testConfiguration.languageVersion && (
                    <Typography mt={1} variant="body1">
                      Language version: {details.testConfiguration.languageVersion}
                    </Typography>
                  )}
                  {details.testConfiguration.operatingSystem && (
                    <Typography mt={1} variant="body1">
                      OS: {details.testConfiguration.operatingSystem}
                    </Typography>
                  )}
                  {details.testConfiguration.testFrameworkVersion && (
                    <Typography mt={1} variant="body1">
                      Test framework version: {details.testConfiguration.testFrameworkVersion}
                    </Typography>
                  )}
                </DetailsBlock>
              </Stack>
            </DetailsBlock>
          </Grid>
          <Grid item xs={4} xl={2.4}>
            <DetailsBlock>
              <DetailsBlockHeader variant="body1">Summary</DetailsBlockHeader>
              <Stack mt={1}>
                <DetailsBlock>Successes: {details.countPerOutcome.success}</DetailsBlock>
                {details.countPerOutcome.fail > 0 && <DetailsBlock>Fails: {details.countPerOutcome.fail}</DetailsBlock>}
                {details.countPerOutcome.error > 0 && <DetailsBlock>Errors: {details.countPerOutcome.error}</DetailsBlock>}
                {details.countPerOutcome.skip > 0 && <DetailsBlock>Skipped: {details.countPerOutcome.skip}</DetailsBlock>}
                {details.countPerOutcome.retried > 0 && <DetailsBlock>Retried: {details.countPerOutcome.retried}</DetailsBlock>}
              </Stack>
            </DetailsBlock>
          </Grid>
          <Grid item xs={4} xl={2.4}>
            <DetailsBlock>
              <DetailsBlockHeader variant="body1" sx={{ textTransform: 'capitalize' }}>{details.ci.toLowerCase()}</DetailsBlockHeader>
              <Typography mt={1} variant="body1">
                <LinkStyled to={details.ciLink} target="_blank">
                  Go to Build
                </LinkStyled>
              </Typography>
            </DetailsBlock>
          </Grid>
        </Grid>
      </Stack>
    </MainCard>
  );
};

export default BuildDetailsHeader;
