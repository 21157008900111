import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, Button, MenuItem, Box, Menu, styled } from '@mui/material';
import { OrganizationContext } from 'contexts/OrganisationsContext';
import { useContext, useState } from 'react';

const ArrowDown = styled(FontAwesomeIcon)`
  width: 12px !important;
  height: 12px !important;
`;

const RepoPicker = () => {
  const { repos, selectRepo, selectedRepo } = useContext(OrganizationContext);
  const [repoSelectanchorEl, setRepoSelectAnchorEl] = useState<null | HTMLElement>(null);

  const isRepoPickerOpen = Boolean(repoSelectanchorEl);

  const handleRepoPickerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setRepoSelectAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setRepoSelectAnchorEl(null);
  };

  return (
    <>
      <Typography sx={{ fontSize: '0.8rem', mt: 1 }}>{'Repository'}</Typography>

      <Button
        id="repo-selector"
        variant="text"
        aria-controls={isRepoPickerOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isRepoPickerOpen ? 'true' : undefined}
        onClick={handleRepoPickerClick}
        disableRipple={true}
        sx={{
          fontSize: '1rem',
          pr: '4px',
          color: '#000',
          padding: '0',
          fontWeight: 500,
          whiteSpace: 'nowrap',
          '::after': {
            boxShadow: 'none'
          },
          ':hover': {
            background: 'none'
          }
        }}
        endIcon={<ArrowDown icon={faChevronDown} />}
      >
        <span style={{ marginRight: 0 }}>{!!selectedRepo && selectedRepo !== 'null' ? selectedRepo : 'Select Repository'}</span>
      </Button>

      {repos.length ? (
        <Box>
          <Menu
            id="basic-menu"
            anchorEl={repoSelectanchorEl}
            open={isRepoPickerOpen}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            {repos.map((repo, idx) => (
              <MenuItem
                key={idx}
                onClick={() => {
                  selectRepo(repo.name);
                  handleClose();
                }}
                sx={{ px: 4 }}
              >
                {repo.name}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      ) : null}
    </>
  );
};

export default RepoPicker;
